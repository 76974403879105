<template>
  <div>
    <v-container fluid>
      <h1 class="titulo">CATEGORIAS</h1>
      <v-skeleton-loader type="table" :loading="skLoader" :transition-group="'fade-transition'">
        <v-list>
          <v-card elevation="5">
            <v-card-text>
              <OrganizzeAddAnyButton :title="'Novo centro de custo'" @onClick="openModalCadastro()"/>

              <v-list-group v-for="costCenter in costCentersMapped" :key="costCenter.id" no-action sub-group>
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-container>
                      <v-row no-gutters>
                        <span style="font-weight: bold;">{{costCenter.name}}</span>
                        <v-spacer></v-spacer>
                        <OrganizzeActionButton
                          :canEdit="permiteAcao($route, 'edit')"
                          :canDelete="permiteAcao($route, 'delete')"
                          @onEdit="editModalCadastro({ costCenter })"
                          @onDelete="openModalExcluir({ costCenter })"
                        />
                      </v-row>
                    </v-container>
                  </v-list-item-content>
                </template>

                <v-list-item v-for="(subCostCenter, index) in costCenter.subCostCenters" :key="index" sub-group>
                  <v-list-item-content>
                    <v-container>
                      <v-row no-gutters>
                        <span class="subCostCenter">{{subCostCenter.name}}</span>
                        <v-spacer></v-spacer>
                        <OrganizzeActionButton
                          :canEdit="permiteAcao($route, 'edit')"
                          :canDelete="permiteAcao($route, 'delete')"
                          @onEdit="editModalCadastro({ costCenter: subCostCenter })"
                          @onDelete="openModalExcluir({ costCenter: subCostCenter })"
                        />
                      </v-row>
                    </v-container>
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>
            </v-card-text>
          </v-card>
        </v-list>
      </v-skeleton-loader>
    </v-container>
    <ModalCadastro/>
    <ModalExcluir/>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { errorSnackbar } from '@/core/service/utils'
import Events from '@/core/service/events'
import { incRequestCounter, REQUEST_KEY_ALL } from '@/utils/miscellaneous'

const namespaceStore = 'organizzeCentroCustos'

export default {
  name: 'OrganizzeCentroCustos',
  props: {},
  directives: {},
  components: {
    OrganizzeAddAnyButton: () => import('@/views/components/organizze/addAnyButton'),
    OrganizzeActionButton: () => import('@/views/components/organizze/actionButton'),
    ModalCadastro: () => import('./components/modalCadastro'),
    ModalExcluir: () => import('./components/modalExcluir'),
  },
  data: () => ({
    skLoader: false
  }),
  created () {},
  mounted () {
    const self = this
    Events.$on('index::getCostCenter', () => { self.buscarCentroCustos() })
    self.buscarCentroCustos()
  },
  computed: {
    ...mapGetters(namespaceStore, ['costCentersMapped', 'subCostCenters']),
    ...mapGetters('roles', ['permiteAcao']),
  },
  watch: {},
  methods: {
    ...mapActions(namespaceStore, ['getCostCenters']),

    openModalCadastro (data) { Events.$emit('modalCadastro::open', data) },
    editModalCadastro (data) { Events.$emit('modalCadastro::edit', data) },
    openModalExcluir (data) { Events.$emit('modalExcluir::open', data) },

    buscarCentroCustos () {
      const self = this
      self.skLoader = incRequestCounter(REQUEST_KEY_ALL, 1)
      self.getCostCenters().then(() => {}).catch(e => {
        console.error(e)
        errorSnackbar(e)
      }).finally(() => {
        self.skLoader = incRequestCounter(REQUEST_KEY_ALL, -1)
      })
    }
  },
  validations () { return {} }
}
</script>

<style lang="scss" scoped>
  .subCostCenter {
    font-size: 14px;
    color: #888888;
  }
</style>